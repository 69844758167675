import { AxiosResponse } from 'axios';

import type { AttachmentsUploadResponse, ListResponse } from '~/interfaces/api';
import type {
    Dashboard, Organization, Bankaccount, Location, FieldConfig,
    Header,
} from '~/interfaces/organization';

import authorizedApiClient from '~/clients/authorizedApiClient';
import { PaymentOptions } from '~/interfaces/order';
import { RecManRecipe } from '~/interfaces/recMan';

export function getOrganizations(): Promise<AxiosResponse<ListResponse<Organization>>> {
    return authorizedApiClient.get('/organization/');
}

export function getOrganization(id: string): Promise<AxiosResponse<Organization>> {
    return authorizedApiClient.get(`/organization/${id}/`);
}

export function updateUser({
    id,
    email,
    role,
    invited,
    language,
}: {
    id: string,
    email: string,
    role: string,
    invited: boolean,
    language: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${id}/user/update`,
        {
            id,
            email,
            role,
            invited,
            language,
        },
    );
}

export function updateContactdata({
    orgId,
    name,
    nick,
    email,
    phone,
    street,
    streetnumber,
    zip,
    city,
    color,
}: {
    orgId: string,
    name: string,
    nick: string,
    email: string,
    phone: string,
    street: string,
    streetnumber: string,
    zip: string,
    city: string,
    color: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/contactData`,
        {
            id: orgId,
            name,
            nick,
            contact: {
                email,
                phone,
                street,
                streetnumber,
                zip,
                city,
            },
            color,
        },
    );
}

export function addUser({
    orgId,
    email,
    role,
    language,
}: {
    orgId: string,
    email: string | null,
    role: string | null,
    language: string | null,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/user`,
        {
            id: orgId,
            role,
            email,
            language,
        },
    );
}

export function withdrawBankaccounts({
    orgId,
    bankaccount,
}: {
    orgId: string,
    bankaccount: Bankaccount,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/bankaccounts/withdraw`,
        {
            id: orgId,
            bankaccount,
        },
    );
}

export function saveBankaccount({
    orgId,
    bankaccount,
}: {
    orgId: string,
    bankaccount: Bankaccount,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/bankaccounts`,
        {
            id: orgId,
            bankaccount,
        },
    );
}

export function getBic({
    iban,
}: {
    iban: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.get(`/x/sepa/${iban}`);
}

export function getDashboard(orgId: string, dashboardId?: string): Promise<AxiosResponse<Dashboard>> {
    return authorizedApiClient.get(`/order/dashboard?orgId=${orgId}&dashboardId=${dashboardId || 'default'}`);
}

export function deleteTag({
    orgId,
    tagId,
}: {
    orgId: string,
    tagId: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/tag/delete`,
        {
            orgId,
            tid: tagId,
        },
    );
}

export function updateTag({
    orgId,
    tagId,
    color,
    label,
}: {
    orgId: string,
    tagId: string,
    color: string,
    label: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/tag/update`,
        {
            orgId,
            tid: tagId,
            color,
            label,
        },
    );
}

export function setDefaultContact({
    orgId,
    contact,
}: {
    orgId: string,
    contact: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/defaultcontact`,
        {
            contact,
            id: orgId,
        },
    );
}

export function setUniqueId({
    orgId,
    uniquematch,
    uniquemodus,
}: {
    orgId: string,
    uniquematch: string,
    uniquemodus: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/unique`,
        {
            id: orgId,
            uniquematch,
            uniquemodus,
        },
    );
}

export function savePaymentOptions({
    orgId,
    paymentoptions,
}: {
    orgId: string,
    paymentoptions: PaymentOptions,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/paymentOptions`,
        {
            id: orgId,
            paymentoptions,
        },
    );
}

export function toggleOrderLanguage({
    orgId,
    orderlanguage,
}: {
    orgId: string,
    orderlanguage: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/orderlanguage`,
        {
            id: orgId,
            orderlanguage,
        },
    );
}

export function changeOrderLanguageDefault({
    orgId,
    orderlanguagedefault,
}: {
    orgId: string,
    orderlanguagedefault: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/orderlanguagedefault`,
        {
            id: orgId,
            orderlanguagedefault,
        },
    );
}

export function saveReminder({
    orgId,
    reminder,
}: {
    orgId: string,
    reminder: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/reminder`,
        {
            id: orgId,
            reminder,
        },
    );
}
export function changeOrgaAccountEmailHandling({
    orgId,
    interval,
    isActive,
}: {
    orgId: string,
    interval: string,
    isActive: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/accountEmailHandling/update`,
        {
            id: orgId,
            interval,
            isActive,
        },
    );
}

export function saveComment({
    orgId,
    title,
    comment,
}: {
    orgId: string,
    title: string,
    comment: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/comment`,
        {
            id: orgId,
            title,
            comment,
        },
    );
}

export function deleteComment({
    orgId,
    commentId,
}: {
    orgId: string,
    commentId: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/comment/delete`,
        {
            id: orgId,
            commentId,
        },
    );
}

export function savePaymentplanConfig({
    orgId,
    config,
}: {
    orgId: string,
    config: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/paymentplan`,
        {
            id: orgId,
            config,
        },
    );
}

export function saveSettings({
    orgId,
    description,
    url,
}: {
    orgId: string,
    description: string,
    url: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/header`,
        {
            id: orgId,
            header: {
                description,
                url,
            },
        },
    );
}

export function saveHeader({
    orgId,
    header,
}: {
    orgId: string,
    header: Header,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/header`,
        {
            id: orgId,
            header,
        },
    );
}

export function saveReference({
    orgId,
    reference,
}: {
    orgId: string,
    reference: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/reference`,
        {
            id: orgId,
            reference,
        },
    );
}

export function saveCleanupSettings({
    orgId,
    closeHidden,
    hideConfirmed,
    hideInactive,
}: {
    orgId: string,
    closeHidden: object,
    hideConfirmed: object,
    hideInactive: object,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/cleanupSettings`,
        {
            id: orgId,
            closeHidden,
            hideConfirmed,
            hideInactive,
        },
    );
}

export function savePaymentSettings({
    orgId,
    paymentsettings,
}: {
    orgId: string,
    paymentsettings: object,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/paymentsettings`,
        {
            id: orgId,
            paymentsettings,
        },
    );
}

export function ordercontactSwitch({
    orgId,
    ordercontact,
}: {
    orgId: string,
    ordercontact: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/ordercontact`,
        {
            id: orgId,
            ordercontact,
        },
    );
}

export function receivepaymentsSwitch({
    orgId,
    receivepayments,
}: {
    orgId: string,
    receivepayments: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/receivepayments`,
        {
            id: orgId,
            receivepayments,
        },
    );
}

export function resetpaymentsSwitch({
    orgId,
    resetpayments,
}: {
    orgId: string,
    resetpayments: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/resetpayments`,
        {
            id: orgId,
            receivepayments: resetpayments,
        },
    );
}

export function orderpickupdateSwitch({
    orgId,
    orderpickupdate,
}: {
    orgId: string,
    orderpickupdate: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/orderpickupdate`,
        {
            id: orgId,
            orderpickupdate,
        },
    );
}

export function orderduedateSwitch({
    orgId,
    orderduedate,
}: {
    orgId: string,
    orderduedate: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/orderduedate`,
        {
            id: orgId,
            duedate: orderduedate,
        },
    );
}

export function orderattachmentSwitch({
    orgId,
    orderattachment,
}: {
    orgId: string,
    orderattachment: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/orderattachment`,
        {
            id: orgId,
            orderattachment,
        },
    );
}

export function orderrecipientSwitch({
    orgId,
    orderrecipient,
}: {
    orgId: string,
    orderrecipient: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/orderrecipient`,
        {
            id: orgId,
            orderrecipient,
        },
    );
}

export function draftsSwitch({
    orgId,
    drafts,
}: {
    orgId: string,
    drafts: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/drafts`,
        {
            id: orgId,
            drafts,
        },
    );
}

export function ordertagsSwitch({
    orgId,
    ordertags,
}: {
    orgId: string,
    ordertags: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/ordertags`,
        {
            id: orgId,
            ordertags,
        },
    );
}

export function updateSubroles({
    orgId,
    email,
    subroles,
}: {
    orgId: string,
    email: string,
    subroles: { adm: boolean, recMan: boolean },
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/subroles`,
        {
            id: orgId,
            email,
            subroles,
        },
    );
}

export function deleteUser({
    orgId,
    email,
}: {
    orgId: string,
    email: string | undefined,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/userDelete`,
        {
            id: orgId,
            email,
        },
    );
}

export function updateRoles({
    orgId,
    email,
    role,
}: {
    orgId: string,
    email: string,
    role: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/setrole`,
        {
            id: orgId,
            email,
            role,
        },
    );
}

export function uploadLogo({
    orgId,
    file,
}: {
    orgId: string,
    file: File,
}): Promise<AxiosResponse<AttachmentsUploadResponse>> {
    return authorizedApiClient.post(
        `/organization/${orgId}/settings/pic`,
        { orgId, attachment: file },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}

export function deleteLogo({
    orgId,
}: {
    orgId: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/reset/pic`,
        {
            id: orgId,
        },
    );
}

export function deleteLocation({
    orgId,
    locationId,
}: {
    orgId: string;
    locationId: string;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/location/delete`,
        {
            orgId,
            locationId,
        },
    );
}

export function updateDefaultLocation({
    orgId,
    locationId,
    status,
}: {
    orgId: string;
    locationId: string;
    status: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/location/default`,
        {
            orgId,
            locationId,
            status,
        },
    );
}

interface CreateLocation extends Partial<Location> {
    orgId: string;
}
export function createLocation(payload: CreateLocation): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${payload.orgId}/location`,
        payload,
    );
}

interface UpdateLocation extends Partial<Location> {
    orgId: string;
    locationId: string;
}
export function updateLocation(payload: UpdateLocation): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${payload.orgId}/location/update`,
        payload,
    );
}

export function toggleSkipGwg({
    orgId,
    skipgwg,
}: {
    orgId: string,
    skipgwg: boolean,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/skipgwg`,
        {
            id: orgId,
            skipgwg,
        },
    );
}

export function saveSkipgwgItems({
    orgId,
    skipgwgitems,
}: {
    orgId: string,
    skipgwgitems: {
        iban: string;
        name: string;
    }[],
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/skipgwgitems`,
        {
            id: orgId,
            skipgwgitems,
        },
    );
}

export function deleteDefaultDocument({
    orgId,
    url,
}: {
    orgId: string,
    url: string,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/deleteDefaultDocument`,
        {
            id: orgId,
            url,
        },
    );
}

export function uploadDefaultDocument({
    orgId,
    file,
}: {
    orgId: string,
    file: File,
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/settings/addDefaultDocument`,
        { orgId, attachment: file },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}
export function updateInvoiceConfig({
    orgId,
    required,
    optional,
    numeric,
}: {
    orgId: string;
    required?: string[];
    optional?: string[];
    numeric?: string[];
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/invoiceConfig`,
        {
            id: orgId,
            required,
            optional,
            numeric,
        },
    );
}

const desiredOrderCustomerFields = [
    'id',
    'company',
    'salutation',
    'gender',
    'title',
    'firstname',
    'lastname',
    'street',
    'streetnumber',
    'zip',
    'city',
    'country',
    'birthdate',
    'email',
    'phone',
    'mobile',
    'fax',
    'fullname',
];

export function updateCustomerConfig({
    orgId,
    required,
    optional,
    numeric,
}: {
    orgId: string;
    required?: string[];
    optional?: string[];
    numeric?: string[];
}): Promise<AxiosResponse> {
    function sortFields(fields?: string[]) {
        if (!fields) return undefined;
        return fields.map((field) => field).sort((a, b) => desiredOrderCustomerFields.indexOf(a) - desiredOrderCustomerFields.indexOf(b));
    }
    const sortedRequiredFields = sortFields(required);
    const sortedOptionalFields = sortFields(optional);
    const sortedNumericFields = sortFields(numeric);
    return authorizedApiClient.post(
        `/organization/${orgId}/customerConfig`,
        {
            id: orgId,
            required: sortedRequiredFields,
            optional: sortedOptionalFields,
            numeric: sortedNumericFields,
        },
    );
}

export function updateVehicleConfig({
    orgId,
    required,
    optional,
    numeric,
}: {
    orgId: string;
    required?: string[];
    optional?: string[];
    numeric?: string[];
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/vehicleConfig`,
        {
            id: orgId,
            required,
            optional,
            numeric,
        },
    );
}

export function updateCaseConfig({
    orgId,
    required,
    optional,
    numeric,
}: {
    orgId: string;
    required?: string[];
    optional?: string[];
    numeric?: string[];
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/caseConfig`,
        {
            id: orgId,
            required,
            optional,
            numeric,
        },
    );
}

export function updateReferenceConfig({
    orgId,
    required,
    optional,
    numeric,
    reference,
}: {
    orgId: string;
    required?: string[];
    optional?: string[];
    numeric?: string[];
    reference?: FieldConfig[];
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/reference`,
        {
            id: orgId,
            required,
            optional,
            numeric,
            reference,
        },
    );
}

export function updateCustomReferenceConfig({
    orgId,
    reference,
}: {
    orgId: string;
    reference: string;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/customReference`,
        {
            id: orgId,
            reference,
        },
    );
}

export function updateCaseFields({
    orgId,
    casefields,
}: {
    orgId: string;
    casefields: any;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/casefields`,
        {
            id: orgId,
            casefields,
        },
    );
}

export function updateInvoiceFields({
    orgId,
    invoicefields,
}: {
    orgId: string;
    invoicefields: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/invoicefields`,
        {
            id: orgId,
            invoicefields,
        },
    );
}

export function updateVehicleFields({
    orgId,
    vehiclefields,
}: {
    orgId: string;
    vehiclefields: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/vehiclefields`,
        {
            id: orgId,
            vehiclefields,
        },
    );
}

export function updateLocationMandatory({
    orgId,
    mandatoryField,
}: {
    orgId: string;
    mandatoryField: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/location.mandatoryField`,
        {
            id: orgId,
            mandatoryField,
        },
    );
}

export function updateCustomerFields({
    orgId,
    customerfields,
}: {
    orgId: string;
    customerfields: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/customerfields`,
        {
            id: orgId,
            customerfields,
        },
    );
}

export function getReport({
    orgId,
    from,
    to,
}: {
    orgId: string;
    from: string;
    to: string;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${orgId}/paymentreport`,
        {
            id: orgId,
            from,
            to,
        },
    );
}

export function importPayments({
    orgId,
    file,
}: {
    orgId: string,
    file: File,
}): Promise<AxiosResponse<AttachmentsUploadResponse>> {
    return authorizedApiClient.post(
        `/organization/${orgId}/payment/import/file`,
        { orgId, attachment: file },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}

export function importOpenItem({
    orgId,
    file,
}: {
    orgId: string,
    file: File,
}): Promise<AxiosResponse<AttachmentsUploadResponse>> {
    return authorizedApiClient.post(
        `/organization/${orgId}/import/accounting/openitem/file`,
        { orgId, attachment: file },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        },
    );
}

export function downloadImportOrdersInstructions(orgId: string): Promise<AxiosResponse> {
    const headers: Record<string, string> = {
        Accept: 'application/pdf',
        'Content-Type': 'application/json',
    };
    return authorizedApiClient.post(
        `/organization/${orgId}/order/import/instruction`,
        { id: orgId },
        {
            headers,
            responseType: 'json',
        },
    );
}

export function downloadImportOrdersTemplate(orgId: string): Promise<AxiosResponse> {
    const headers: Record<string, string> = {
        Accept: 'application/csv',
        'Content-Type': 'application/json',
    };
    return authorizedApiClient.post(
        `/organization/${orgId}/order/import/template`,
        { id: orgId },
        {
            headers,
            responseType: 'blob',
        },
    );
}

export function settlementReport(payload: {
    orgId: string;
    batch: string;
    force: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        '/adyen/processsettlementreport',
        payload,
    );
}

export function settlementReportNew(payload: {
    orgId: string;
    batch: string;
    force: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        '/adyen/processsettlementreport/new',
        payload,
    );
}

export function sendInstallmentSettlementReport(payload: {
    orgId: string;
    reference: string;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        '/installment/settlementReport',
        payload,
    );
}
export function saveBundledDunningStatus(payload: {
    orgId: string;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${payload.orgId}/receivablesManagement/setMonthlyDun`,
        payload,
    );
}
export function recManSaveRecipe(payload: {
    orgId: string;
    recipe: RecManRecipe[]
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        `/organization/${payload.orgId}/receivablesManagement/saveRecipe`,
        payload,
    );
}
export function getSwanPayoutBalance(payload: {
    orgId: string;
    accountId: string;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        '/swan/payoutBalance',
        payload,
    );
}
export function initiateSwanPayout(payload: {
    orgId: string;
    accountId: string;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        '/swan/payout',
        payload,
    );
}
export function getSwanPayoutList(orgId: string, accountId: string): Promise<AxiosResponse> {
    return authorizedApiClient.get(`/swan/payoutList?orgId=${orgId}&accountId=${accountId}`);
}

export function saveBundledOrderTags(payload: {
    orgId: string;
    tags: string[];
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        '/organization/bundledOrderTags',
        payload,
    );
}

export function savePayButtonSettings(payload: {
    id: string;
    payButtonDisabled: boolean;
}): Promise<AxiosResponse> {
    return authorizedApiClient.post(
        '/organization/payButtonSettings',
        payload,
    );
}