const getDomain = (_withSubdomain = true):string => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    if (parts.length > 2) {
        const sliceLength = _withSubdomain ? 3 : 2;
        return parts.slice(parts.length - sliceLength).join('.');
    }
    return hostname;
};

let domainVar = getDomain();

function getURL(): string | undefined {
    const { hostname } = window.location;

    switch (hostname) {
    case 'mein.bezahl.de':
    case 'future.bezahl.de':
    case 'dashboard.bezahl.de':
        domainVar = getDomain(false);
        return 'https://api.bezahl.de';

    case 'future.aufinity.com':
    case 'dashboard.aufinity.com':
        domainVar = getDomain(false);
        return 'https://api.aufinity.com';

    case 'mein.partner.nx.bezahl.de':
    case 'future.partner.nx.bezahl.de':
    case 'dashboard.partner.nx.bezahl.de':
        return 'https://api.partner.nx.bezahl.de';

    case 'mein.local.nx-t.dev':
    case 'future.local.nx-t.dev':
    case 'dashboard.local.nx-t.dev':
        return 'https://api.local.nx-t.dev';
    case 'dashboard.dev-pro.aufinity.dev':
        return 'https://api.dev-pro.aufinity.dev';
    default:
        // none;
    }

    const stack = hostname.replace(/mein-|future-|dashboard-|.nx-t\.dev/g, '');
    if (stack) {
        return `https://api-${stack}.nx-t.dev`;
    }
    console.error('Finding the correct api url failed');
    return undefined;
}

function getBackendURL(version = 1): string {
    return `${getURL()}/nxt/v${version}`;
}

export const backendUrl = getBackendURL();
export const backendUrlV6 = getBackendURL(6);
export const socketBackendUrl = getURL();
export const domain = domainVar;
